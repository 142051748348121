<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('yongjinlog')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-list
        v-if="rewardlogs.length"
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.nomore')"
      >
        <van-row
          class="currency-Increase"
          v-for="item in rewardlogs"
          :key="item.id"
        >
          <van-col
            :span="12"
            style="text-align:left"
          >
            <span>{{ $t('xiajiid') }}：{{ item.from_id }}</span>
            <br />
            <span style="color:reg(128,128,128)">
                      {{ $t('jljine') }} : <span>{{ item.money }}</span>
                    </span>
            <br />
          </van-col>
          <van-col
            :span="12"
            class="currency-time"
          >
                    <span>
                      <span v-if="item.type == 1">
                        {{ $t('jlyy') }}{{ item.from_id }}{{ $t('jlyyy1') }}</span>
                      <span v-if="item.type == 2">
                        {{ $t('jlyy') }}{{ item.from_id }}{{ $t('jlyyy2') }}</span>
                       <span v-if="item.type == 3">
                        {{ $t('jlyy') }}{{ item.from_id }}{{ $t('jlyyy3') }}</span>
                     <span v-if="item.type == 4">
                        {{ $t('jlyy') }}{{ item.from_id }}{{ $t('jlyyy4') }}</span>
                      <br/>
                      <span style="color: rgb(128, 128, 128);">
                        {{ item.createtime | dateformat }}
                      </span>
                    </span>
          </van-col>
        </van-row>
      </van-list>
      <van-empty
        :description="$t('common.nomore')"
        v-else
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: [],
      rewardlogs: [],
      withdraws: [],
      transfers: [],
      finished: true,
      loading: false
    }
  },
  created() {
    this.getRewardlogs()
  },
  mounted() {},
  methods: {
    // 充值记录
    async getRewardlogs() {
      const { data } = await this.$http.get('/home/trade/get_rewardlogs')
      if (data) {
        if (data.code === 200) {
          this.rewardlogs = data.data
        }
      }
    },
    change(name) {
      console.log(name)
      switch (name) {
        case 'tab2':
          this.getOrders()
          break
        case 'tab1-1':
          this.getRecharges()
          break
        case 'tab1-2':
          this.getWithdraws()
          break
      }
    }
  }
}
</script>
<style lang="less" scoped>
.maincontent {
  padding: 64px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  height: 100vh;
  /deep/.van-tab {
    font-size: 1.04rem;
  }
  .currency-Increase {
    background-color: #fff;
    color: #000;
    padding: 0.8rem 1.33333rem 0.8rem 1.33333rem;
    line-height: 1.6rem;
    font-size: 0.8rem;
    display: flex;
    border-bottom: 0.02667rem solid #e5e5e5;
    .currency-time {
      text-align: right;
    }
  }
}
</style>
